import * as React from 'react';
import { createRoot } from 'react-dom/client';

export const mountReactComponents = componentContext => {
  const components = new Map();

  Object.keys(componentContext).forEach(path => {
    let componentName;
    if (path.endsWith('index.tsx')) {
      componentName = `${path.split('/').at(-2)}/index`;
    } else {
      componentName = path.split('/').pop().replace('.tsx', '');
    }
    components.set(
      componentName,
      React.lazy(() => componentContext[path]())
    );
  });
  // get element with any data-react-class
  const componentRootElements = document.querySelectorAll('[data-react-class]');
  componentRootElements.forEach(rootElement => {
    const componentName = rootElement.getAttribute('data-react-class');
    let Component;
    if (components.has(`${componentName}/index`)) {
      Component = components.get(`${componentName}/index`);
    } else {
      Component = components.get(componentName);
    }

    const props = JSON.parse(rootElement.getAttribute('data-react-props'));
    const root = createRoot(rootElement);
    root.render(
      <React.Suspense fallback={<div></div>}>
        <Component {...props} />
      </React.Suspense>
    );
  });
};
